.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  height: 52px;
  padding: 0 24px;
  text-decoration: none;
  transition: .1s ease-in-out;
  cursor: pointer;
  &:disabled {
    opacity: .5;
    cursor: default;
  }
  &_type {
    &_primary {
      color: $color-white;
      background: $color-blue-primary;
      &:hover {
        background: $color-blue-primary-hover;
      }
      &:active {
        background: $color-blue-primary-active;
      }
    }
    &_secondary {
      color: $color-text;
      border: 1px solid $color-blue-primary;
      &:hover {
        color: $color-blue-primary;
        border-color: $color-blue-primary-hover;
      }
      &:active {
        color: $color-blue-primary-hover;
        border-color: $color-blue-primary-active;
      }
    }
  }
}