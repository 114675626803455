@mixin sm {
  @media (min-width: $breakpoint-sm) {
    @content;
  }
}
@mixin md {
  @media (min-width: $breakpoint-md) {
    @content;
  }
}
@mixin lg {
  @media (min-width: $breakpoint-lg) {
    @content;
  }
}
@mixin xl {
  @media (min-width: $breakpoint-xl) {
    @content;
  }
}
@mixin xxl {
  @media (min-width: $breakpoint-xxl) {
    @content;
  }
}
@mixin xxxl {
  @media (min-width: $breakpoint-xxxl) {
    @content;
  }
}