.form {
  display: grid;
  gap: 40px;
}
.form__control {
  input {
    display: flex;
    align-items: center;
    width: 100%;
    height: 52px;
    padding: 0 24px;
    font-size: 20px;
    color: $color-text;
    border-bottom: 1px solid $color-blue-primary-hover;
    background: $color-white;
    transition: .15s ease-in-out;
    &::placeholder {
      color: $color-blue-primary;
      opacity: 0.7;
    }
    &:focus {
      border-color: $color-blue-primary-active;
      box-shadow: 0 0 0 4px rgba($color-blue-primary-active, 0.10);
      outline: 0;
      background: $color-white;
    }
  }
}
