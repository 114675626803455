.menu-item {
  a {
    display: inline-flex;
    height: 48px;
    width: 100%;
    padding: 0 24px 0 48px;
    align-items: center;
    text-decoration: none;
    color: $color-text;
    opacity: 1!important;
    cursor: pointer;
    @include xl {
      padding: 0 16px;
    }
    @include xxxl {
      height: 52px;
      padding: 0 24px;
    }
    &:hover{
      color: $color-blue-primary;
    }
    &:active{
      color: $color-blue-primary-hover;
      background: $color-blue-bg;
    }
  }
  &_type {
    &_icon {
      a {
        width: 52px;
        padding: 0;
        justify-content: center;
        font-size: 28px;
      }
    }
  }
}