.entry {
  @include md {
    display: grid;
    grid-template-columns: 1fr 240px;
    gap: 24px 60px;
  }
  @include xl {
    grid-template-columns: 420px 264px 420px;
    justify-content: space-between;
    gap: 0;
    margin: 64px 24px ;
  }
  @include xxl {
    margin: 64px auto;
  }
  @include xxxl {
    margin: 116px auto;
  }
  &__picture {
    position: relative;
    z-index: 1;
    height: 293px;
    margin: 0 -8px;
    @include md {
      grid-column: 2;
      //width: 240px;
      height: 560px;
      margin: 0;
    }
    @include xxl {
      height: 620px;
    }
    //Сетка
    &:before {
      content: '';
      position: absolute;
      left: 50%;
      margin-left: -132px;
      width: 264px;
      height: 294px;
      background: url("/img/sot-pattern.svg");
      @include md {
        width: 240px;
        height: 100%;
        margin-left: -120px;
      }
      @include xl {
        width: 264px;
        margin-left: -132px;
      }
      @include xxl {
        width: 312px;
        margin-left: -156px;
      }
    }
    //Тень
    &:after {
      content: '';
      position: absolute;
      top: 100px;
      left: 50%;
      width: 312px;
      height: 243px;
      transform: translate(-50%,0);
      background: url("/img/panel-shadow.svg");
      background-size: cover;
      @include md {
        top: 214px;
        width: 472px;
        height: 367px;
      }
      @include xxl {
        top: 230px;
        width: 541px;
        height: 421px;
      }
    }
  }
  &__image {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    max-width: 470px;
    transform: translate(-50%,-53%);
    z-index: 1;
    @include md {
      width: 470px;
    }
    @include xxl {
      width: 540px;
      max-width: none;
    }
  }
  &__hero {
    padding: 24px 16px 56px;
    @include sm {
      padding-top: 32px;
    }
    @include md {
      grid-column: 1;
      grid-row: 1;
      position: relative;
      height: 560px;
      padding: 51px 40px 56px;
      align-content: space-between;
      border: 1px solid $color-brown-primary;
      &:before {
        content: '';
        position: absolute;
        right: -2px;
        top: 59px;
        width: 3px;
        height: 445px;
        background: $color-white;
      }
    }
    @include xxl {
      height: 620px;
      padding: 82px 40px 86px;
    }
  }
  &__hero-text {
    @include md {
      margin-right: -70px;
    }
  }
  &__hero-buttons {
    @include xxl {
      margin-right: -90px;
    }
  }
  &__form {
    display: grid;
    gap: 40px;
    padding: 48px 16px;
    background: $color-blue-bg;
    @include md {
      grid-column: 1/3;
      grid-template-columns: 1fr 1fr;
      padding: 48px 40px;
    }
    @include xl {
      grid-column: 3;
      grid-template-columns: none;
      align-content: center;
      padding: 0 40px;
    }
    h2 {
      font-size: 20px;
      margin: 0 0 8px;
      @include md {
        grid-column: 2;
        margin: -6px 0 auto;
      }
      @include xl {
        grid-column: 1;
        margin: 0 0 8px;
      }
    }
    .form {
      @include md {
        grid-row: 1/3;
      }
      @include xl {
        grid-row: 2;
      }
    }
  }
  &__form-input{
    input {
      background: transparent;
      .entry__form:hover & {
        background: white;
      }
    }
  }
  &__form_agreement {
    font-size: 16px;
    @include md {
      margin: auto 0 -4px;
    }
    @include lg {
      margin: 0;
    }
  }
  // Анимация панели и тени
  &__animation_show {
    .entry__image {
      opacity: 0;
      transform: translate(-50%,-100%);
      transition: .8s ease-in-out;
    }
    &:before {
      opacity: 0;
      transition: 1.5s ease-in-out;
    }
    &:after {
      opacity: 0;
      transform: translate(-50%,20%);
      transition: .8s ease-in-out;
    }
    &.animation_end {
      .entry__image {
        opacity: 1;
        transform: translate(-50%,-53%);
      }
      &:before {
        opacity: 1;
      }
      &:after {
        opacity: 1;
        transform: translate(-50%,0);
      }
    }

  }
}
