
.header {
  position: fixed;
  display: flex;
  left: 0;
  right: 0;
  top: 0;
  height: 84px;
  align-items: center;
  justify-content: space-between;
  padding: 0 4px 0 16px;
  z-index: 100;
  transition: .15s ease-in-out;
  @include md {
    height: 100px;
    padding: 0 12px 0 24px;
  }
  @include xl {
    height: 144px;
    padding: 24px 8px 24px 24px;
  }
  @include xxl {
    justify-content: center;
  }
  @include xxxl {
    height: 120px;
    padding: 16px 8px 16px 24px;
  }
  &.scrolled {
    background: $color-white;
    box-shadow: 0 0 24px 0 rgba($color-text, 0.1);
    @include xl {
      height: 84px;
      padding-top: 16px;
    }
    .header__contacts-wrap {
      height: 0;
      opacity: 0;
      overflow: hidden;
      transition: .25s ease-in-out;
    }
  }
  &__wrap {
    @include xl {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
      height: 100%
    }
    @include xxl {
      max-width: 1800px;
    }
    @include xxxl {
      align-items: center;
    }
  }
  &__logo {
    display: flex;
    align-items: center;
    gap: 16px;
    text-decoration: none;
    span {
      font-size: 20px;
      font-weight: $weight-bold;
      color: $color-text;
      @include md {
        font-size: 24px;
      }
    }
  }
  &__menu {
    margin-bottom: 16px;
    @include xl {
      display: flex;
      margin: 0;
    }
  }
  &__contacts-wrap {
    @include xl {
      display: flex;
      overflow: hidden;
      transition: opacity .15s .15s ease-in-out;
    }
    @include xxxl {
      height: auto!important;
      opacity: 1!important;
    }
  }
  &__contacts{
    @include xl {
      display: flex;
    }
  }
  &__messengers {
    display: flex;
    padding: 0 34px;
    @include xl {
      padding: 0 4px 0 0;
    }
  }
  &__toggle-menu {
    display: none;
    position: fixed;
    right: 0;
    top: 0;
    width: 320px;
    padding: 68px 0;
    z-index: 10;
    background: $color-white;
    box-shadow: 0 4px 24px 0 rgba($color-text, 0.25);
    @include md {
      right: 8px;
      top: 16px;
      padding: 60px 0;
    }
    @include xl {
      display: grid!important;
      justify-items: flex-end;
      position: static;
      width: auto;
      padding: 2px 0 0;
      box-shadow: none;
    }
    @include xxxl {
      display: flex!important;
      gap: 52px;
      padding: 0;
    }
  }
  &__btn-burger {
    display: flex;
    width: 52px;
    height: 52px;
    justify-content: center;
    align-items: center;
    z-index: 20;
    background: $color-white;
    @include xl {
      display: none!important;
    }
    &:active {
      background: $color-blue-bg;
    }
    div {
      position: relative;
      display: grid;
      align-content: space-between;
      justify-items: end;
      width: 28px;
      height: 17px;
      &:before, span, &:after {
        display: block;
        width: 28px;
        height: 1px;
        background: $color-text;
        transform-origin: center;
        transition: .15s ease-in-out;
      }
      &:before {
        content: '';
      }
      &:after {
        content: '';
        width: 20px;
      }
    }
    &.opened {
      div {
        &:before {
          transform: translateY(8px) rotate(45deg);
        }
        span {
          transform: scaleX(0);
        }
        &:after {
          width: 28px;
          transform: translateY(-8px) rotate(-45deg);
        }
      }
    }
  }
  &__backdrop {
    display: none;
    position: fixed;
    inset: 0;
    z-index: 1;
    background: $color-blue-primary;
    opacity: .2;
    @include xl {
      display: none!important;
    }
  }
}