@font-face {
  font-family: 'icomoon';
  src:
          url('../fonts/icomoon.ttf?iyimx8') format('truetype'),
          url('../fonts/icomoon.woff?iyimx8') format('woff'),
          url('../fonts/icomoon.svg?iyimx8#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-expand_more:before {
  content: "\e900";
}
.icon-viber:before {
  content: "\e901";
}
.icon-Whatsapp:before {
  content: "\e902";
}
